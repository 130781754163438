import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {environment} from "@environment";

import { catchError, throwError, Observable, of } from "rxjs";

import { ICreditCardDetails, ISubmitPayment, ITransferCall } from "../../interfaces";
import { ISendLinkPayload, ISendLinkResponse } from 'src/app/shared/components/link-transfer/link-transfer.interface';
import { ICancelPayload } from 'src/app/shared/components/card-details/card-details.interface';
import { ICardOnFile } from 'src/app/shared/components/card-on-file/card-on-file.interface';

import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})

export class CreditCardService {

  paymentBasePath = environment.commonPaymentApiBasePath;
  transferBasePath = environment.ivrCallTransfer;

  constructor(private http: HttpClient, private dataSrv: DataService) {}

  getCreditCardDetails = (refId: string, paymentInfoType: boolean) => {
    return this.http.get<ICreditCardDetails>(`${this.paymentBasePath}${paymentInfoType ? 'payment-info' : 'getByReferenceId'}/${refId}`)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  transferCall = (agnId = '', refId = '') => {
    return this.http.post<ITransferCall>(`${this.transferBasePath}transfer/agents/${agnId}/uids/${refId}`, {})
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  successfulTransferBack = (status: string, refId: string | null) => {
    return this.http.post<string>(`https://qa.payment.cinchhs.com/commonPayment/callstatus/${refId}/${status}`,{}, {responseType: 'text' as 'json'})
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  submitCreditCardPayment = (paymentId = 0, referenceId = '', createdBy = '', requestId = '',
                             revenueType = '', paymentCategory = '', taxAmount = '', clientId = '',
                             paymentAmount = '', customerAccountId = 0, paymentProfileId = '', paymentActionType = '') => {
    const creditCardPaymentBody = {
      paymentId,
      referenceId,
      createdBy,
      requestId,
      revenueType,
      paymentCategory,
      taxAmount,
      clientId,
      paymentAmount,
      customerAccountId,
      paymentProfileId,
      paymentActionType,
    }

    return this.http.put<ISubmitPayment>(`${this.paymentBasePath}${paymentActionType != 'Customer Initiated' ? 'payment-info/' + paymentActionType : 'payment-info'}`, creditCardPaymentBody)
      .pipe(
        catchError((err) => {
          console.log('Error while submitting card', err);
          return throwError(() => err);
        })
      );
  }

  // Methods added for the new routes
  getCreditCardDetailsNew(refId: string, paymentInfoType: boolean): Observable<any> {
    return this.http.get<any>(`${this.paymentBasePath}${paymentInfoType ? 'payment-info' : 'getByReferenceId'}/${refId}`);
  }

  getCardOnFileDetails(): Observable<any> {
    if (!this.dataSrv.cardData$.value?.contractNumber) { console.log('No Contract Number to fetch card on file data'); return of(false); }
     
    return this.http.get<ICardOnFile[] | null>(`${this.paymentBasePath}cards-on-file?contractId=${Math.trunc(Number(this.dataSrv.cardData$.value?.contractNumber))}`);
  }

  submitPayment(payload: any): Observable<any> {
    const paymentInfoType = this.dataSrv.paymentInfoType$.value;
    let requestUrl = 'updatePaymentDetails';

    if (paymentInfoType && payload.paymentActionType) {
      requestUrl = (payload.paymentActionType != 'Customer Initiated') ? 'payment-info/' + payload.paymentActionType : 'payment-info';
    }

    return this.http.post<ISubmitPayment>(`${this.paymentBasePath}${requestUrl}`, payload);
  }

  cancelPayment(payload: ICancelPayload): Observable<any> {
    return this.http.put(`${this.paymentBasePath}cancelPayment`, payload);
  }

  captureBySMS(payload: ISendLinkPayload): Observable<ISendLinkResponse> {
    return this.http.post<ISendLinkResponse>(`${this.paymentBasePath}sms/capture-link`, payload);
  }

  captureByEmail(payload: ISendLinkPayload): Observable<ISendLinkResponse> {
    return this.http.post<ISendLinkResponse>(`${this.paymentBasePath}email/capture-link`, payload);
  }
}
