<div class="card-on-file-container section-wrapper">
    <h3><strong>AVAILABLE CARD ON FILE</strong></h3>

    <div>
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let data">
                    <mat-checkbox [(ngModel)]="isCardOnFileSelected" (click)="$event.preventDefault()" [color]="checkBoxColor"></mat-checkbox>
                </td>
            </ng-container>
  
            <!-- First name Column -->
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef> First Name </th>
                <td mat-cell *matCellDef="let data" (click)="$event.preventDefault()"> {{ data.firstName | titlecase }} </td>
            </ng-container>
    
            <!-- Middle name Column -->
            <ng-container matColumnDef="middleName">
                <th mat-header-cell *matHeaderCellDef> Middle Name </th>
                <td mat-cell *matCellDef="let data" (click)="$event.preventDefault()"> {{ data.middleName || '-' | titlecase }} </td>
            </ng-container>
        
            <!-- last name Column -->
            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef> Last Name </th>
                <td mat-cell *matCellDef="let data" (click)="$event.preventDefault()"> {{data.lastName | titlecase}} </td>
            </ng-container>
        
            <!-- last four Column -->
            <ng-container matColumnDef="lastFour">
                <th mat-header-cell *matHeaderCellDef> Last Four digits </th>
                <td mat-cell *matCellDef="let data" (click)="$event.preventDefault()"> {{data.lastFour}} </td>
            </ng-container>
    
            <!-- last four Column -->
            <ng-container matColumnDef="sourceSystem">
                <th mat-header-cell *matHeaderCellDef> Source System </th>
                <td mat-cell *matCellDef="let data" (click)="$event.preventDefault()"> {{data.sourceSystem}} </td>
            </ng-container>
    
            <!-- is active Column -->
            <ng-container matColumnDef="paymentProfileDefault">
                <th mat-header-cell *matHeaderCellDef> Default </th>
                <td mat-cell *matCellDef="let data" (click)="$event.preventDefault()"> {{data.paymentProfileDefault}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns;"
                (click)="applyCardData(row)"
                [ngClass]="(cardDataSelected === row) ? 'active-row' : ''"
                matTooltip="Apply card"
                [matTooltipPosition]="'after'"
                [matTooltipDisabled]="isCardOnFileSelected"
            ></tr>
        </table>
    </div>

    <br>
    
    <div class="no-data-message" *ngIf="!dataSource.data?.length">
        <span data-auto-id="no-card-on-file-message">No cards on file available for this contract.</span>
    </div>
</div>
