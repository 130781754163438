import {Component, OnInit} from '@angular/core';
import {CreditCardService} from "../../services/creditCardService/credit-card.service";
import {ActivatedRoute} from "@angular/router";
import {faRandom, faSync, faArrowAltCircleRight} from "@fortawesome/free-solid-svg-icons";
import {ICreditCardDetails} from "../../interfaces";

@Component({
  selector: 'app-credit-card-details',
  templateUrl: './credit-card-details.component.html',
  styleUrls: ['./credit-card-details.component.scss']
})
export class CreditCardDetailsComponent implements OnInit {

  referenceId: string | null;
  paymentRefId: string | null;
  paymentError = false;
  initialLoader = true;
  noData = false;
  noRefId = false;
  apiError = false;
  ivrSuccess = false;
  ivrError = false;
  refreshTrigger = false;
  refreshSuccess = false;
  noAgentId = false;
  agentId: string;
  successFullPayment = false;
  paymentFailed = false;
  paymentNoMatch = false;
  submitButtonStatus = false;
  errMsg : string;
  cardDts: ICreditCardDetails;
  transferIcon = faRandom;
  refreshIcon = faSync;
  submitIcon = faArrowAltCircleRight;
  favoriteSeason: string;
  transferBackValue :string;
  seasons: string[] = ['Yes', 'No'];
  radioDisSts = false;
  submitBtnSts = false;

  constructor(private route: ActivatedRoute, private creditCardService: CreditCardService) {
  }

  ngOnInit(): void {

    this.route.queryParamMap.subscribe(params => {
      this.paymentRefId = params.get('refId');
    });

    if (this.paymentRefId) {
      this.commonPaymentCreditDetails();
    } else {
      this.initialLoader = false;
      this.noRefId = true;
    }
  }

  radioCall = () => {
    if(this.favoriteSeason === 'Yes'){
      this.transferBackValue = 'Y'
    } else {
      this.transferBackValue = 'N'
    }
    this.creditCardService.successfulTransferBack(this.transferBackValue , this.paymentRefId).subscribe({
      next : (dts) => {
        this.submitBtnSts = false;
        console.log(dts, 'succ');
      }, error : (err) => {
        console.log(err, 'err');
      }
    })
  }
  commonPaymentCreditDetails = () => {
    if (this.paymentRefId) {
      this.creditCardService.getCreditCardDetails(this.paymentRefId, true).subscribe({
        next: (cardDts) => {
          this.initialLoader = false
          if (cardDts.successStatus) {
            if (this.refreshTrigger) {
              this.refreshSuccess = true;
            }
            this.cardDts = cardDts;
          } else {
            this.noData = true;
          }
        },
        error: () => {
          this.initialLoader = false;
          this.apiError = true;
        }
      })
    }
  }

  ivrTransfer = () => {
    this.agentId = (<HTMLInputElement>document.getElementById("agentId")).value;
    this.initialLoader = true;
    this.refreshSuccess = false;
    this.refreshTrigger = false;
    this.ivrSuccess = false;
    this.ivrError = false;
    this.noRefId = false;
    this.noAgentId = false;
    this.successFullPayment = false;
    this.paymentFailed = false;
    this.paymentNoMatch = false;
    this.submitButtonStatus = false;
    if (this.paymentRefId && this.agentId) {
      this.creditCardService.transferCall(this.agentId, this.paymentRefId).subscribe({
        next: (res) => {
          this.initialLoader = false;
          if (res.Status === 'OK') {
            this.ivrSuccess = true;
            this.radioDisSts = true;
            this.submitBtnSts = true;
          }
        },
        error: () => {
          this.initialLoader = false;
          this.ivrError = true;
        }
      })
    } else if (!this.agentId) {
      this.initialLoader = false;
      this.noAgentId = true;
    } else {
      this.initialLoader = false;
      this.noRefId = true;
    }
  }

  refreshDetails = () => {
    this.initialLoader = true;
    this.refreshTrigger = true;
    this.refreshSuccess = false;
    this.noAgentId = false;
    this.noRefId = false
    this.apiError = false;
    this.ivrError = false;
    this.ivrSuccess = false;
    this.successFullPayment = false;
    this.paymentFailed = false;
    this.paymentNoMatch = false;
    this.submitButtonStatus = false;
    if (this.paymentRefId) {
      this.commonPaymentCreditDetails();
      this.radioDisSts = false;
    } else {
      this.initialLoader = false;
      this.noRefId = true;
    }
  }

  submitPayment = () => {
    this.initialLoader = true;
    this.successFullPayment = false;
    this.paymentFailed = false;
    this.paymentNoMatch = false;
    this.submitButtonStatus = false;
    this.noRefId = false
    this.apiError = false;
    this.ivrError = false;
    this.ivrSuccess = false;
    this.refreshSuccess = false;
    this.refreshTrigger = false;
    this.noAgentId = false;

    // Modified paymentActionType to come from commonPaymentData
    // instead of hardcoding
    // let paymentActionType = 'Hold';

    const {
      paymentId, referenceId, createdBy, requestId, revenueType, paymentCategory, taxAmount, clientId, paymentAmount,
      customerAccountId, paymentProfileId,cardLastFour, paymentActionType,
    } = this.cardDts.commonPaymentData;

    const paymentInfoType = true;

    if (paymentId && referenceId && createdBy && requestId && revenueType && paymentCategory && taxAmount
      && clientId && paymentAmount && customerAccountId && paymentProfileId && paymentActionType && cardLastFour && paymentInfoType) {

      this.creditCardService.submitCreditCardPayment(paymentId, referenceId, createdBy, requestId, revenueType,
        paymentCategory, taxAmount, clientId, paymentAmount, customerAccountId, paymentProfileId, paymentActionType).subscribe({
        next: (payRes) => {
          if (payRes.paymentStatus === 'Completed' && payRes.paymentProfileId === paymentProfileId) {
            this.initialLoader = false;
            this.successFullPayment = true;
          } else {
            this.initialLoader = false;
            this.paymentNoMatch = true;
            this.errMsg = payRes.statusId;
          }
        },
        error: () => {
          this.initialLoader = false;
          this.paymentFailed = true;
        }
      })
    } else {
      this.initialLoader = false;
      this.submitButtonStatus = true;
    }
  }

}
