import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { CreditCardService } from 'src/app/services/creditCardService/credit-card.service';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-ivr-transfer',
  templateUrl: './ivr-transfer.component.html',
  styleUrls: ['./ivr-transfer.component.scss']
})
export class IvrTransferComponent implements OnInit {
  cleaner$ = new Subject<void>();
  refId: string | null = null;
  agentId: string | null = null;

  constructor(
    private dataSrv: DataService,
    private creditCardSrv: CreditCardService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.agentId = localStorage.getItem('agentId') || null;
    this.dataSrv.refId$.pipe(takeUntil(this.cleaner$)).subscribe({
      next: (id) => {
        this.refId = id;
      }
    })
  }

  ivrTransfer(): void {
    if (!this.agentId || !this.refId) { return; }

    this.creditCardSrv.transferCall(this.agentId, this.refId).pipe(takeUntil(this.cleaner$)).subscribe({
      next: (response) => {
        this.snackBar.open('The call has been Transferred successfully!', 'Close');
        this.dataSrv.transferred$.next(true);
      },
      error: (err) => {
        this.snackBar.open('Error while transferring call, service unavailable. Please try again later.', 'Close');
        this.dataSrv.transferred$.next(false);
      }
    })
  }

}
