import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonPaymentData } from 'src/app/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  refId$ = new BehaviorSubject<string | null>(null);
  captureByLinkRefId$ = new BehaviorSubject<string | null>(null);
  paymentInfoType$ = new BehaviorSubject<boolean>(false);
  cardData$ = new BehaviorSubject<CommonPaymentData | null>(null);
  requestSuccess$ = new BehaviorSubject<boolean | null>(null);
  transferred$ = new BehaviorSubject<boolean | null>(null);

  originalCardData: CommonPaymentData | null;

  constructor() { }

  saveOriginalCardData(data: CommonPaymentData | null): void {
    this.originalCardData = data;
  }

  useOriginalCardData(): void {
    this.cardData$.next(this.originalCardData);
  }
}
