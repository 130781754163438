<div class="card-wrapper section-wrapper">
    <h3>CAPTURE</h3>

    <div class="first-section">
        <div class="status" *ngIf="processing">
            <div class="spinner"><mat-spinner></mat-spinner></div>
            <span>Collecting credit card details from the customer</span>
        </div>

        <div class="no-data-message" *ngIf="!processing"></div>

        <div class="refresh">
            <mat-slide-toggle
                [(ngModel)]="autoRefresh"
                (ngModelChange)="refreshWithInterval()"
                [disabled]="!transferred"
                name="auto-refresh"
                data-auto-id="auto-refresh"
                ngDefaultControl
            >
                Auto Refresh
            </mat-slide-toggle>
    
            <br>
    
            <button mat-flat-button
                color="primary"
                *ngIf="!autoRefresh"
                (click)="getCreditCardDetails()"
                [disabled]="!transferred"
                matTooltip="Link has to be sent to customer to enable Refresh"
                [matTooltipPosition]="'before'"
                [matTooltipDisabled]="transferred"
            >
                Refresh
            </button>
        </div>
    </div>

    <app-link-transfer></app-link-transfer>
</div>
