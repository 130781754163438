<div class="terms-modal">
    <h4 mat-dialog-title><strong>Payment Collection</strong></h4>
    <mat-dialog-content>
        <p>&ldquo;Please be advised that this call IS being recorded and MAY be monitored to improve quality.</p>
        <p>
            <strong>{{ data.customerName ? data.customerName : '&#60;Customer Name&#62;' }}</strong>, in connection with
            your {{ data.productName ? data.productName : '&#60;Product Name&#62;' }} Plan, you
            authorize Cinch to charge the credit card you are providing today, {{ data.today | date: 'M/d/yyyy' }}
            the deductible amount of ${{ data.estAmt || '0' | number: '1.2-2' }} {{ !data.multiItem ? 'per service request' : 'for EACH covered appliance item'}}.
        </p>
        <p>
            <strong>{{ data.customerName ? data.customerName : '&#60;Customer Name&#62;' }}</strong>, with the
            understanding that the payment arrangement made today {{ data.today | date: 'M/d/yyyy' }}
            does not modify the terms of your Cinch Service Plan, do I have your authorization to proceed?&rdquo;
        </p>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button
            mat-flat-button
            color="primary"
            [mat-dialog-close]="true"
        >
            Close
        </button>
    </mat-dialog-actions>
</div>

