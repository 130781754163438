<router-outlet></router-outlet>

<div *ngIf="refPres else errBlk">

  <div class="container bg-white" *ngIf="UserData">
    <div class="bg-white">

      <div class="PaymentHead">Payment Details</div>
      <hr>

      <div class="form-group box ">
        <form #formForCC autocomplete="new-form" aria-autocomplete="no" class="needs-validation">
          <div class="row">
            <div class=" col-lg-8 col-sm-8 col-xs-8">
              <img class="ccimg col-lg-8" #ccimage src="../assets/images/ccblank.png" alt="card type" />
            </div>
            <div class="col-lg-4 col-sm-4 col-xs-4 text-right ">
              <p style="font-weight: bold; margin: auto; padding-top: 25px; padding-right: 20px;"> Total : ${{estAmt}}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <legend>Card information</legend>
              <div class="form-group">
                <label for="contactName" class="sr-only">Name *</label>
                <input autocomplete="new-custname" #custname (change)="isNameValid(custname.value)" value="{{postName}}"
                  class="form-control" name="contactName" placeholder="Name on card" type="text" required
                  disabled="true">

              </div>
              <div class="row">
                <div class="form-group col-sm-12">
                  <input type="text" #card class="form-control" id="creditCardDetailsMasked"
                    placeholder="XXXX-XXXX-XXXX-XXXX" required disabled="true" name="creditCardDetailsMasked"
                    value="XXXX-XXXX-XXXX-{{UserData.commonPaymentData?.cardLastFour}}">
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-6 col-xl-6 col-md-6 mt-4 align-self-center">
                  <legend> PaymentType :</legend>
                  <span *ngIf="UserData.commonPaymentData?.paymentType">{{UserData.commonPaymentData?.paymentType}}</span>
                </div>
                <div class="col-lg-6 col-sm-6 col-xl-6 col-md-6 mt-4">
                  <div class="row">
                    <div class="col-6 align-self-center">
                      <div class="text-right">
                        <legend>Agent Id :</legend>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <label for="agentId" class="sr-only">agentId</label>
                        <input type="text" class="form-control h-35" id="agentId" required name="agentId" value="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <legend>Billing address</legend>
              <div class="form-group">
                <label for="streetAddress" class="sr-only">Address Line 1 *</label>
                <input class="form-control" name="streetAddress" (change)="isStrAddValid(addLine1.value)" #addLine1
                  placeholder="Address Line 1" type="text" required value="{{postAdd1}}" disabled="true">

              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="city" class="sr-only">City *</label>
                  <input class="form-control" name="city" #city (change)="isCityValid(city.value)" placeholder="City"
                    type="text" number required value="{{postCity}}" disabled="true">
                </div>
                <div class="form-group col-lg-3">
                  <label for="state" class="sr-only">State *</label>
                  <input class="form-control" name="state" #state (change)=" isStateValid()" placeholder="state"
                    type="text" number required value="{{postState}}" disabled="true">
                </div>
                <div class="form-group col-lg-3">
                  <label for="zipCode" class="sr-only">Zip *</label>
                  <input class="form-control" #zip name="zipCode" value="{{postZip}}" (change)="isZipValid(zip.value)"
                    placeholder="Zip" maxlength="5" type="text" required disabled="true">
                </div>
              </div>
            </div>


          </div>

          <div class="row">
            <div class="align-self-center col-lg-3 col-sm-3 col-xl-3 col-md-3">
              <button type="button" class="btn btn-info" (click)="ivrTransfer()" id="TransferButton" [disabled]="enableReturnBtn">Transfer
                <fa-icon [icon]="transferIcon"></fa-icon>
              </button>
            </div>
            <div class="align-self-center col-lg-3 col-sm-3 col-xl-3 col-md-3">
              <button type="button" class="btn btn-primary btn-refresh" (click)="refreshDetails()"
                id="refreshCall">Refresh
                <fa-icon [icon]="refreshIcon"></fa-icon>
              </button>
            </div>
            <div class="align-self-center col-lg-3 col-sm-3 col-xl-3 col-md-3" *ngIf="!enableReturnBtn">
              <p id="example-radio-group-label">Call Returned Successfully ?</p>
              <label for="calldetails-1" class="radio-labels pr-1">
                <input size=25 [(ngModel)]="favoriteSeason" [disabled]="radioDisSts" (change)="radioCall()"
                  id="calldetails-1" type="radio" name="calldetails" value="Yes" checked="checked"
                  class="mr-1" />Yes</label>
              <label for="calldetails-2" class="radio-labels pl-1">
                <input [(ngModel)]="favoriteSeason" [disabled]="radioDisSts" (change)="radioCall()" id="calldetails-2"
                  type="radio" name="calldetails" value="No" class="mr-1" />No</label>
            </div>
            <div class="align-self-center col-lg-3 col-sm-3 col-xl-3 col-md-3">
              <button *ngIf="!enableReturnBtn" type="submit" class="btn btn-success" (click)="validateAndPost()" id="submitCall"
                [disabled]="!(UserData.commonPaymentData?.cardLastFour && UserData.commonPaymentData?.paymentProfileId && favoriteSeason)">Submit
                <fa-icon [icon]="submitIcon" width="20"></fa-icon>
              </button>
              <button *ngIf="enableReturnBtn" type="submit" class="btn btn-success" (click)="redirectToCaller()" id="submitCall" style="float: right;"
                >Return
              <!--   <fa-icon [icon]="submitIcon" width="20"></fa-icon> -->
              </button>
              <a (click)="makeCancelCall()" class="btn-link cancel-btn" *ngIf="!enableReturnBtn" >Cancel</a>
              <a (click)="openGeneralScript()" *ngIf="showGeneralScript"><u><b>General Payment Collection</b></u></a>
              <a (click)="openMultiItemScript()" *ngIf="showMultiItemScript"><u><b>P3 Appliance Multi-item</b></u></a>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>


  <!-- Button to Open the Modal -->
  <button #modShow style="display: none;" data-backdrop="static" data-keyboard="false" type="button"
    class="btn btn-primary" data-toggle="modal" data-target="#conf">

  </button>

  <button #loadshow style="display: none;" data-backdrop="static" data-keyboard="false" type="button"
    class="btn btn-primary" data-toggle="modal" data-target="#load">
  </button>

  <button #generalScriptShow style="display: none;" data-backdrop="static" data-keyboard="false" type="button"
    class="btn btn-primary" data-toggle="modal" data-target="#generalScript">
  </button>

  <button #multiItemScriptShow style="display: none;" data-backdrop="static" data-keyboard="false" type="button"
    class="btn btn-primary" data-toggle="modal" data-target="#multiItemScript">
  </button>

  <!-- The Modal -->
  <div class="modal" style="margin: auto;" id="conf">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">
            <img *ngIf="modalTitle == 'Error' && errorText == 'Invalid'" width="30"
              src="../../assets/images/alert-error.png" />
            {{modalTitle}}
          </h4>

        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div *ngIf="pmSuccess else failed" class="text-center">

          <!--   <div *ngIf="authText">The card has successfully been authorized for the diagnostic fee.
              <br />
              <br />
              <span class="note">Note: This is a temporary hold on the amount, and the charge will not process until the
                service pro completes a diagnosis.</span>
            </div> -->
            <div *ngIf="authText">The credit card details capture is completed. </div>
            <br>
            <div *ngIf="alreadyComp && !cardCancelled"> The credit card details capture is completed.</div>
            <div *ngIf="cardCancelled"> The Payment Details Capture was canceled. Will return to the Schedule Service website. </div>

          </div>
          <ng-template #failed>
            <div class="text-center" *ngIf="modalTitle !== 'Error'|| errorText == 'Request Error'"> Payment not
              successful!</div>
            <div class="text-center"> {{errRes}}</div>
          </ng-template>
        </div>

        <!-- Modal footer -->
        <div *ngIf="!isSPM">
          <div class="modal-footer">
            <button type="button" (click)="redirectToCaller()" class="btn btn-success center-btn"
              data-dismiss="modal">{{Close}}</button>
          </div>
        </div>
        <div *ngIf="!pmSuccess && isSPM">
          <div class="modal-footer">
            <button type="button" class="btn btn-success" data-dismiss="modal">Ok</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" style="margin: auto;" id="load">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header ">
          <div class="col-lg-12 modal-title text-center" *ngIf="loading else rediring">
            <h6 style="color: #00dd54;">Payment is being processed</h6><br>
          </div>
          <ng-template #rediring>
            <h5 class="col-lg-12 modal-title text-center" style="color: #00dd54;">Redirecting...</h5><br>
          </ng-template>
        </div>
        <div class="modal-body row">
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">&nbsp;</div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"><img style="margin: auto;"
              style="max-width: 200px; max-height: 200px; display: block; margin-left: auto; margin-right: auto; width: 40%;"
              src="../assets/images/Loadingscreen.gif" alt="Please wait while processing" /></div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">&nbsp;</div>
          <button #closeloader style="display: none;" type="button" class="btn btn-success"
            data-dismiss="modal"></button>
        </div>



      </div>
    </div>
  </div>


  <!-- General Payment Collection Script Modal Start -->
  <div class="modal" style="margin: auto;" id="generalScript">
    <div class="modal-dialog modal-dialog-centered modal-dialog-script">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Payment Collection
          </h4>
        </div>
        <div class="modal-body script-content">
          <p>&ldquo;Please be advised this call IS being recorded and MAY be monitored to improve quality.</p>
          <p><span class="name">{{ customerName ? customerName : '&#60;Customer Name&#62;' }}</span>, in connection with
            your {{ contractInfo ? contractInfo.productName : '&#60;Product Name&#62;' }} Plan, you
            authorize Cinch to charge the credit card you are providing today, {{ today | date: 'M/d/yyyy' }}
            the deductible amount of ${{ estAmt | number: '1.2-2' }} per service request.</p>
          <p><span class="name">{{ customerName ? customerName : '&#60;Customer Name&#62;' }}</span>, with the
            understanding that the payment arrangement made today {{ today | date: 'M/d/yyyy' }}
            does not modify the terms of your Cinch Service Plan, do I have your authorization to proceed?&rdquo;</p>
        </div>
        <div class="modal-footer">
          <button #generalScriptClose type="button" class="btn btn-success center-btn" data-dismiss="modal"
            (click)="closeGeneralScript()">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- General Payment Collection Script Modal End -->


  <!-- Multi-item Payment Collection Script Modal Start -->
  <div class="modal" style="margin: auto;" id="multiItemScript">
    <div class="modal-dialog modal-dialog-centered modal-dialog-script">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Payment Collection
          </h4>
        </div>
        <div class="modal-body script-content">
          <p>&ldquo;Please be advised this call IS being recorded and MAY be monitored to improve quality.</p>
          <p><span class="name">{{ customerName ? customerName : '&#60;Customer Name&#62;' }}</span>, in connection with
            your {{ contractInfo ? contractInfo.productName : '&#60;Product Name&#62;' }} Plan, you
            authorize Cinch to charge the credit card you are providing today, {{ today | date: 'M/d/yyyy' }}
            the deductible amount of ${{ estAmt | number: '1.2-2' }} for EACH covered appliance item.</p>
          <p><span class="name">{{ customerName ? customerName : '&#60;Customer Name&#62;' }}</span>, with the
            understanding that the payment arrangement made today {{ today | date: 'M/d/yyyy' }}
            does not modify the terms of your Cinch Service Plan, do I have your authorization to proceed?&rdquo;</p>
        </div>
        <div class="modal-footer">
          <button #multiItemScriptClose type="button" class="btn btn-success center-btn" data-dismiss="modal"
            (click)="closeMultiItemScript()">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Multi-item Payment Collection Script Modal End -->
  <div class="container">
    <div class="col-lg-12 col-sm-12 col-xl-12 col-md-12 d-flex justify-content-center mt-5" *ngIf="initialLoader">
      <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="paymentError">
      <div class="alert alert-danger text-center" role="alert">
        Payment can not be processed : Invalid Details!
      </div>
    </div>
    <div *ngIf="enableReturnBtn">
      <div class="alert alert-danger text-center" role="alert">
        Your request was not successful. Please return to the Agent Website.
      </div>
    </div>
    <div *ngIf="StatusIdNotSuccess">
      <div class="alert alert-danger text-center" role="alert">
        The profile was not created. Please click transfer to try again, or cancel to return to the Agent website. The error message is: {{UserData.commonPaymentData.statusId}}
      </div>
    </div>
    <div *ngIf="apiError">
      <div class="alert alert-danger text-center" role="alert">
        SomeThing Went Wrong. Please Try again Later!
      </div>
    </div>
    <div *ngIf="noData">
      <div class="alert alert-info text-center" role="alert">
        No Payment Details found for the given Reference Id!
      </div>
    </div>
    <div *ngIf="noRefId">
      <div class="alert alert-danger text-center" role="alert">
        No Reference Id was provided!
      </div>
    </div>
    <div *ngIf="noAgentId">
      <div class="alert alert-danger text-center" role="alert">
        No Agent Id was provided!
      </div>
    </div>
    <div *ngIf="ivrSuccess">
      <div class="alert alert-success text-center" role="alert">
        The call has been Transferred!
      </div>
    </div>
    <div *ngIf="ivrError">
      <div class="alert alert-danger text-center" role="alert">
        SomeThing Went Wrong. Please Try again Later!
      </div>
    </div>
    <div *ngIf="refreshSuccess">
      <div class="alert alert-success text-center" role="alert">
        <strong>Refresh!</strong> is Successful and Data is updated!
      </div>
    </div>
    <div *ngIf="successFullPayment">
      <div class="alert alert-success text-center" role="alert">
        <strong>Payment!</strong> is Successful.
      </div>
    </div>
    <div *ngIf="paymentFailed">
      <div class="alert alert-danger text-center" role="alert">
        <strong>Payment!</strong> didn't go through.
      </div>
    </div>
    <div *ngIf="paymentNoMatch">
      <div class="alert alert-info text-center" role="alert">
        <strong>Payment!</strong> didn't go through. <strong>{{errRes}}!</strong>
      </div>
    </div>
    <div *ngIf="submitButtonStatus">
      <div class="alert alert-info text-center" role="alert">
        Can't submit as all the necessary details are not available!
      </div>
    </div>
  </div>

</div>
<ng-template #errBlk>
  <div class="container bg-white">
    <div class="bg-white">

      <div class="PaymentHead">Payment Details</div>
      <hr class="row">
      <div class="alert-danger">Payment can not be processed : {{errRes}} </div>
    </div>
  </div>
</ng-template>