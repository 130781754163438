import { Component, OnInit, OnDestroy } from '@angular/core';

import { ThemePalette } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';

import { CreditCardService } from 'src/app/services/creditCardService/credit-card.service';
import { DataService } from 'src/app/services/data/data.service';
import { MessageService } from 'src/app/services/message/message.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

import { ICardOnFile } from './card-on-file.interface';
import { CommonPaymentData } from 'src/app/interfaces';

@Component({
  selector: 'app-card-on-file',
  templateUrl: './card-on-file.component.html',
  styleUrls: ['./card-on-file.component.scss']
})
export class CardOnFileComponent implements OnInit, OnDestroy {
  cleaner$ = new Subject<void>();
  existingCardDetails: ICardOnFile[];
  dataSource = new MatTableDataSource<ICardOnFile>();
  displayedColumns = ['action', 'firstName', 'middleName', 'lastName', 'lastFour', 'sourceSystem', 'paymentProfileDefault'];
  cardDataSelected: ICardOnFile;
  cardData: CommonPaymentData | null = null;
  isCardOnFileSelected: boolean = false;
  checkBoxColor: ThemePalette = 'primary';

  constructor(
    private creditCardSrv: CreditCardService,
    private dataSrv: DataService,
    private messageSrv: MessageService,
    private loggerSrv: LoggerService,
  ) { }

  ngOnDestroy(): void {
    this.cleaner$.next();
    this.cleaner$.complete();
  }

  ngOnInit(): void {
    this.dataSrv.cardData$.pipe(takeUntil(this.cleaner$)).subscribe({
      next: (card) => {
        if (!card) { return; }
        this.cardData = card;
        this.getCardOnFileDetails();
      }
    });
  }

  getCardOnFileDetails(): void {
    this.creditCardSrv.getCardOnFileDetails().pipe(takeUntil(this.cleaner$)).subscribe({
      next: (res) => {
        if (!res) { this.existingCardDetails = []; this.dataSource.data = []; return;}

        // filter the data with lastName
        this.existingCardDetails = res.filter((record: ICardOnFile) => record.paymentProfileDefault === 'Y');
        this.dataSource.data = this.existingCardDetails;
      },
      error: (err) => {
        this.messageSrv.showMessage('Error occurred while fetching card on file details.', 'error');
      }
    });
  }

  applyCardData(row: any): void {
    this.isCardOnFileSelected = !this.isCardOnFileSelected;

    if (this.isCardOnFileSelected) {
      this.cardDataSelected = row;
      this.dataSrv.saveOriginalCardData({...this.cardData} as CommonPaymentData);
      const cardToBeModified = this.dataSrv.cardData$.value;
      if (!cardToBeModified) { this.loggerSrv.log('No card details available.'); return; }

      cardToBeModified.firstName = this.cardDataSelected.firstName;
      cardToBeModified.lastName = this.cardDataSelected.lastName;
      cardToBeModified.cardLastFour = this.cardDataSelected.lastFour;
      cardToBeModified.paymentProfileId = this.cardDataSelected.paymentProfileId;

      this.dataSrv.cardData$.next(cardToBeModified);
    } else {
      this.dataSrv.useOriginalCardData();
    }
  }
}
