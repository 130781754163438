import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptureComponent } from './components/capture/capture.component';

import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { FormsModule } from '@angular/forms';
import { CardOnFileComponent } from './components/card-on-file/card-on-file.component';
import { CardDetailsComponent } from './components/card-details/card-details.component';
import { TermsModalComponent } from './components/modals/terms-modal/terms-modal.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { IvrTransferComponent } from './components/ivr-transfer/ivr-transfer.component';
import { LinkTransferComponent } from './components/link-transfer/link-transfer.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { LogoComponent } from './components/logo/logo.component'

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    CaptureComponent,
    CardOnFileComponent,
    CardDetailsComponent,
    TermsModalComponent,
    ConfirmModalComponent,
    IvrTransferComponent,
    LinkTransferComponent,
    LogoComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [
    CaptureComponent,
    CardOnFileComponent,
    CardDetailsComponent,
    ConfirmModalComponent,
    LogoComponent,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatRadioModule,
    MatTooltipModule,
    MatIconModule,
    MatCheckboxModule,
  ]
})
export class SharedModule { }
