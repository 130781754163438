import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CreditCardDetailsComponent } from './components/credit-card-details/credit-card-details.component';
import { CreditCardService } from "./services/creditCardService/credit-card.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio'
import { FormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { PaymentsComponent } from './components/payments/payments.component';
import { SharedModule } from './shared/shared.module';
import { MessageService } from './services/message/message.service';
import { LoggerService } from './services/logger/logger.service';
import { UtilService } from './services/util/util.service';
import { DataService } from './services/data/data.service';

@NgModule({
  declarations: [
    AppComponent,
    CreditCardDetailsComponent,
    PaymentsComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatRadioModule,
    FormsModule,
    SharedModule,
  ],
  providers: [
    CreditCardService,
    DataService,
    MessageService,
    LoggerService,
    UtilService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
