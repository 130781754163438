import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { CommonPaymentData } from 'src/app/interfaces';
import { ISendLinkPayload, ISendLinkResponse } from './link-transfer.interface';

import { CreditCardService } from 'src/app/services/creditCardService/credit-card.service';
import { DataService } from 'src/app/services/data/data.service';
import { MessageService } from 'src/app/services/message/message.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-link-transfer',
  templateUrl: './link-transfer.component.html',
  styleUrls: ['./link-transfer.component.scss']
})
export class LinkTransferComponent implements OnInit, OnDestroy {
  cleaner$ = new Subject<void>();
  customerMobileNumber: number | null;
  customerEmailId: string | null;
  cardData: CommonPaymentData | null = null;
  sendLinkMode: 'mobile' | 'email' = 'mobile';
  constructor(
    private dataSrv: DataService,
    private creditCardSrv: CreditCardService,
    private messageSrv: MessageService,
    private loggerSrv: LoggerService
  ) { }

  ngOnDestroy(): void {
    this.cleaner$.next();
    this.cleaner$.complete();
  }

  ngOnInit(): void {
    this.customerMobileNumber = Number(this.dataSrv.cardData$.value?.phone) || null;
    this.customerEmailId = this.dataSrv.cardData$.value?.emailId || '';

    this.dataSrv.cardData$.pipe(takeUntil(this.cleaner$)).subscribe({
      next: (data) => { this.cardData = data || null; }
    });
  }

  enableSendLink(): boolean {
    if (this.cardData?.paymentProfileId) { return false;}

    return this.checkFieldValidation();
  }

  checkFieldValidation(): boolean {
    if (this.sendLinkMode === 'mobile') {
      return this.customerMobileNumber?.toString().length === 10;
    }
    return this.customerEmailId ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.customerEmailId) : false;
  }

  sendLink(): void {
    if (this.sendLinkMode === 'mobile') {
      this.sendLinkViaSMS();
    } else {
      this.sendLinkViaEmail();
    }
  }

  sendLinkViaSMS(): void {
    if (!this.cardData || !this.customerMobileNumber) { this.loggerSrv.log('No card details or customer mobile number available to send link.'); return; }

    const payload: ISendLinkPayload = {
      phone: this.customerMobileNumber.toString(),
      clientId: this.cardData.clientId,
      referenceId: this.cardData.referenceId,
      name: `${this.cardData.firstName} ${this.cardData.lastName}`
    };

    this.creditCardSrv.captureBySMS(payload).pipe(takeUntil(this.cleaner$)).subscribe({
      next: (response: ISendLinkResponse) => {
        if (!response.successStatus) {
          this.messageSrv.showMessage(response.errorMessage || 'Error occurred, Link to capture is not sent successfully through SMS to customer\'s mobile', 'error');
          return;
        }

        this.dataSrv.transferred$.next(true);
        this.dataSrv.captureByLinkRefId$.next(response.captureByLinkRefId);
        this.messageSrv.showMessage('Link to capture is sent successfully through SMS to customer\'s mobile', 'success');
      },
      error: (err) => {
        this.dataSrv.transferred$.next(false);
        this.messageSrv.showMessage(err.error?.errorMessage ? JSON.parse(err.error.errorMessage).message : 'Error occurred while sending link to customer\'s mobile, Please try again later', 'error');
      }
    });
  }

  sendLinkViaEmail(): void {
    if (!this.cardData || !this.customerEmailId) { this.loggerSrv.log('No card details or customer email id available to send link.'); return; }

    const payload: ISendLinkPayload = {
      clientId: this.cardData.clientId,
      referenceId: this.cardData.referenceId,
      name: `${this.cardData.firstName} ${this.cardData.lastName}`,
      customerEmailTo: this.customerEmailId,
    };

    this.creditCardSrv.captureByEmail(payload).pipe(takeUntil(this.cleaner$)).subscribe({
      next: (response: ISendLinkResponse) => {
        if (!response.successStatus) {
          this.messageSrv.showMessage(response.errorMessage || 'Error occurred, Link to capture is not sent successfully through Email to customer\'s emailId', 'error');
          return;
        }

        this.dataSrv.transferred$.next(true);
        this.dataSrv.captureByLinkRefId$.next(response.captureByLinkRefId);
        this.messageSrv.showMessage('Link to capture is sent successfully through Email to customer\'s emailId', 'success');
      },
      error: (err) => {
        this.dataSrv.transferred$.next(false);
        this.messageSrv.showMessage(err.error?.errorMessage ? JSON.parse(err.error.errorMessage).message : 'Error occurred while sending link to customer\'s emailId, Please try again later', 'error');
      }
    });
  }
}
