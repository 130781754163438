<div class="container bg-white">

  <div class="PaymentHead">
    <span>Payment Details</span>
  </div>

  <hr class="row">

  <div class="form-group box" *ngIf="cardDts">
    <div class="row">
      <div class="col-lg-8 col-sm-8 col-xl-8 col-md-8">
        <img class="ccImg col-lg-8" src="../assets/images/ccblank.png" alt="card type"/>
      </div>
      <div class="col-lg-4 col-sm-4 col-xl-4 col-md-4 text-right">
        <p style="font-weight: bold; margin: auto; padding-top: 25px; padding-right: 20px;"> Total :
          ${{cardDts.commonPaymentData.paymentAmount}}</p>
      </div>
    </div>
    <form autocomplete="off" id="creditCardDetails">
      <div class="container">
        <div class="row">
        <div class="col-lg-12 col-sm-6 col-xl-6 col-md-12">
          <legend>Card information</legend>
          <div class="form-group">
            <label for="contactName" class="sr-only">contactName</label>
            <input type="text" class="form-control" id="contactName" placeholder="Name on card" required disabled="true"
                   name="contactName"
                   value="{{cardDts.commonPaymentData.firstName + ' ' + cardDts.commonPaymentData.lastName}}">
          </div>
          <div class="form-group">
            <label for="creditCardDetailsMasked" class="sr-only">Last 4 digits</label>
            <input type="text" class="form-control" id="creditCardDetailsMasked" placeholder="XXXX-XXXX-XXXX-XXXX"
                   required disabled="true" name="creditCardDetailsMasked"
                   value="XXXX-XXXX-XXXX-{{cardDts.commonPaymentData.cardLastFour}}">
          </div>
          <div class="col-lg-12 col-sm-12 col-xl-12 col-md-12 d-flex">
            <div class="form-group col-lg-6 col-sm-6 col-xl-6 col-md-6 mt-4">
              PaymentType: {{cardDts.commonPaymentData.paymentType}}
            </div>
            <div class="form-group col-lg-6 col-sm-6 col-xl-6 col-md-6 d-flex">
              <div class=" col-lg-6 col-sm-6 col-xl-6 col-md-6 mt-4"> Agent Id :</div>
              <div class=" col-lg-6 col-sm-6 col-xl-6 col-md-6 mt-3">
                <label for="agentId" class="sr-only">agentId</label>
                <input type="text" class="form-control h-35" id="agentId"  required  name="agentId" value="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-sm-6 col-xl-6 col-md-12">
          <legend>Billing Address</legend>
          <div class="form-group">
            <label for="billingAdr" class="sr-only">billing address</label>
            <input type="text" class="form-control" id="billingAdr" placeholder="Billing Address" required
                   disabled="true" name="billingAdr" value="{{cardDts.commonPaymentData.addressLine1}}">
          </div>
          <div class="col-lg-12 col-sm-12 col-xl-12 col-md-12 d-flex">
            <div class="form-group col-lg-6 col-sm-6 col-xl-6 col-md-6">
              <label for="city" class="sr-only">city</label>
              <input type="text" class="form-control ml-28n" id="city" placeholder="city" required disabled="true"
                     name="city" value="{{cardDts.commonPaymentData.city}}">
            </div>
            <div class="form-group col-lg-2 col-sm-2 col-xl-2 col-md-2">
              <label for="state" class="sr-only">state</label>
              <input type="text" class="form-control" id="state" placeholder="st" required disabled="true" name="state"
                     value="{{cardDts.commonPaymentData.state}}">
            </div>
            <div class="form-group col-lg-4 col-sm-4 col-xl-4 col-md-4">
              <label for="zipCode" class="sr-only">zipCode</label>
              <input type="text" class="form-control ml-28" id="zipCode" placeholder="zipCode" required disabled="true"
                     name="zipCode" value="{{cardDts.commonPaymentData.zip}}">
            </div>
          </div>
        </div>
      </div>
      </div>
    </form>
    <div class="col-lg-12 col-sm-8 col-xl-12 col-md-8 d-flex">
      <div class="form-group col-lg-3 col-sm-3 col-xl-3 col-md-3">
        <button type="button" class="btn btn-info" (click)="ivrTransfer()" id="TransferButton">Transfer
          <fa-icon [icon]="transferIcon"></fa-icon>
        </button>
      </div>
      <div class="form-group col-lg-3 col-sm-3 col-xl-3 col-md-3">
        <button type="button" class="btn btn-primary btn-reFresh" (click)="refreshDetails()" id="refreshCall">Refresh
          <fa-icon [icon]="refreshIcon"></fa-icon>
        </button>
      </div>
      <div class="form-group col-lg-3 col-sm-3 col-xl-3 col-md-3">
        <label id="example-radio-group-label">Call Returned Successfully ?</label>
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group rdo"
          [disabled] = "radioDisSts"
          [(ngModel)]="favoriteSeason"
          (change)="radioCall()">
          <mat-radio-button class="example-radio-button" *ngFor="let season of seasons" [value]="season">
            {{season}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-group col-lg-3 col-sm-3 col-xl-3 col-md-3">
        <button type="submit" class="btn btn-success" (click)="submitPayment()" id="submitCall" [disabled]="submitBtnSts">Submit
          <fa-icon [icon]="submitIcon"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-sm-12 col-xl-12 col-md-12 d-flex justify-content-center mt-5" *ngIf="initialLoader">
    <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="paymentError">
    <div class="alert alert-danger text-center" role="alert">
      Payment can not be processed : Invalid Details!
    </div>
  </div>
  <div *ngIf="apiError">
    <div class="alert alert-danger text-center" role="alert">
      SomeThing Went Wrong. Please Try again Later!
    </div>
  </div>
  <div *ngIf="noData">
    <div class="alert alert-info text-center" role="alert">
      No Payment Details found for the given Reference Id!
    </div>
  </div>
  <div *ngIf="noRefId">
    <div class="alert alert-danger text-center" role="alert">
      No Reference Id was provided!
    </div>
  </div>
  <div *ngIf="noAgentId">
    <div class="alert alert-danger text-center" role="alert">
      No Agent Id was provided!
    </div>
  </div>
  <div *ngIf="ivrSuccess">
    <div class="alert alert-success text-center" role="alert">
      The call has been Transferred!
    </div>
  </div>
  <div *ngIf="ivrError">
    <div class="alert alert-danger text-center" role="alert">
      SomeThing Went Wrong. Please Try again Later!
    </div>
  </div>
  <div *ngIf="refreshSuccess">
  <div class="alert alert-success text-center" role="alert">
    <strong>Refresh!</strong> is Successful and Data is updated!
  </div>
  </div>
  <div *ngIf="successFullPayment">
    <div class="alert alert-success text-center" role="alert">
      <strong>Payment!</strong> is Successful.
    </div>
  </div>
  <div *ngIf="paymentFailed">
    <div class="alert alert-danger text-center" role="alert">
      <strong>Payment!</strong> didn't go through.
    </div>
  </div>
  <div *ngIf="paymentNoMatch">
    <div class="alert alert-info text-center" role="alert">
      <strong>Payment!</strong> didn't go through.  <strong>{{errMsg}}!</strong>
    </div>
  </div>
  <div *ngIf="submitButtonStatus">
    <div class="alert alert-info text-center" role="alert">
      Can't submit as all the necessary details are not available!
    </div>
  </div>
</div>
