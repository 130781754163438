import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { CommonPaymentData, ICreditCardDetails } from 'src/app/interfaces';
import { IUpdateCardPayload } from '../card-details/card-details.interface';

import { CreditCardService } from 'src/app/services/creditCardService/credit-card.service';
import { DataService } from 'src/app/services/data/data.service';
import { MessageService } from 'src/app/services/message/message.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.scss']
})
export class CaptureComponent implements OnInit, OnDestroy {
  cleaner$ = new Subject<void>();
  cardData: CommonPaymentData | null = null;
  refId: string | null;
  captureByLinkRefId: string | null;
  processing: boolean = false;
  refreshNeeded: any;
  transferred: boolean = false;
  autoRefresh: boolean = false;

  constructor(
    private dataSrv: DataService,
    private creditCardSrv: CreditCardService,
    private messageSrv: MessageService,
    private loggerSrv: LoggerService
  ) { }

  ngOnDestroy() {
    this.cleaner$.next();
    this.cleaner$.complete();
  }

  ngOnInit(): void {
    this.dataSrv.refId$.pipe(takeUntil(this.cleaner$)).subscribe({
      next: (refId) => {
        this.refId = refId;
      }
    });

    this.dataSrv.captureByLinkRefId$.pipe(takeUntil(this.cleaner$)).subscribe({
      next: (refId) => {
        this.captureByLinkRefId = refId;
      }
    });

    this.dataSrv.transferred$.pipe(takeUntil(this.cleaner$)).subscribe({
      next: (res) => {
        this.transferred = !!res;
      }
    });

    this.dataSrv.cardData$.pipe(takeUntil(this.cleaner$)).subscribe({
      next: (data) => {
        this.cardData = data;
      }
    });
  }

  refreshWithInterval(): void {
    if (!this.transferred) { this.loggerSrv.log('Link is not sent to the customer to proceed with refresh.'); return; }

    if (this.autoRefresh) {
      // setting processing to true to start the loading icon
      this.processing = true;
      this.refreshNeeded = setInterval(() => {
        this.getCreditCardDetails();
      }, 7000);
    } else {
      this.cancelRefresh();
    }
  }

  getCreditCardDetails(): void {
    this.processing = true;
    this.captureByLinkRefId && this.creditCardSrv.getCreditCardDetailsNew(this.captureByLinkRefId, false).pipe(takeUntil(this.cleaner$)).subscribe({
      next: (res: any) => {
        if (!res) { this.processing = false; this.loggerSrv.log('No reponse from commonPayment for capture reference Id.'); return; }
   
        switch(res.commonPaymentData.statusId.toLowerCase()) {
          case 'active':
            // do nothing but wait for the response
            // processing has to be set to false if refresh button is clicked instead of auto-refresh
            if (!this.autoRefresh) this.processing = false;
            break;
          case 'success':
            this.messageSrv.showMessage('The credit card details has been collected from the customer and capture is completed successfully.', 'success');
            // adding this condition so the update can be done immediately for TIMX
            if (this.cardData?.paymentCategory === 'TIMX') {
              // this timeout has to stay as the api needs some time to get through
              setTimeout(() => {
                this.updatePaymentDetails(res.commonPaymentData);
              }, 1000);
            } else {
              // if its not timx, then submit button needs to be clicked to do the update api call
              this.updateDetailsLocally(res.commonPaymentData);
            }
            // disable refresh button since the link is sent and the capture is successful
            this.dataSrv.transferred$.next(false);
            break;
          case 'cancelled':
            this.messageSrv.showMessage('Customer has cancelled the transaction and no CC data was processed. Please confirm with the customer.', 'error');
            break;
          default:
            if (res.errorMessage.length) {
              // display error message if its not success
              this.messageSrv.showMessage(`The credit card details capture is not successful! ${res.errorMessage || ''}`, 'error');
            }
            break;
        }

        if (res.commonPaymentData.statusId.toLowerCase() !== 'active') {
          // cancel refresh once there is a success or a failure
          if (this.autoRefresh) this.cancelRefresh();
          else this.processing = false;
        }
      },
      error: (err) => {
        this.processing = false;
        this.messageSrv.showMessage(err.error?.errorMessage ? err.error.errorMessage : 'Error occurred while fetching credit card details. Please try again.', 'error');
      }
    });
  }

  updatePaymentDetails(captureByLinkRefResponse: CommonPaymentData): void {
    const payload: IUpdateCardPayload = {
      paymentId: this.cardData?.paymentId || 0,
      referenceId: this.cardData?.referenceId || '',
      clientId: this.cardData?.clientId || '',
      paymentProfileId: captureByLinkRefResponse.paymentProfileId,
      firstName: captureByLinkRefResponse.firstName,
      lastName: captureByLinkRefResponse.lastName,
      cardNumber: captureByLinkRefResponse.cardLastFour,
      transactionId: captureByLinkRefResponse.transactionId,
      addressLine1: captureByLinkRefResponse.addressLine1,
      addressLine2: captureByLinkRefResponse.addressLine2,
      city: captureByLinkRefResponse.city,
      state: captureByLinkRefResponse.state,
      zip: captureByLinkRefResponse.zip,
    };

    this.cardData && this.creditCardSrv.submitPayment(payload).pipe(takeUntil(this.cleaner$)).subscribe({
      next: (response: ICreditCardDetails) => {
        if (response.commonPaymentData.statusId.toLowerCase() !== 'success') {
          this.messageSrv.showMessage(response.errorMessage || 'Error occurred while updating card details. Please try again.', 'error');
          return;
        }

        this.dataSrv.cardData$.next(response.commonPaymentData);
        this.dataSrv.requestSuccess$.next(true);
      },
      error: (err) => {
        this.messageSrv.showMessage(err.error?.errorMessage ? err.error.errorMessage : 'Error occurred while updating card details. Please try again.', 'error');
      }
    });
  }

  updateDetailsLocally(captureByLinkRefResponse: CommonPaymentData): void {
    if (!this.cardData) { this.loggerSrv.log('No Card details available for proceeding with update.'); return; }

    this.cardData.paymentProfileId = captureByLinkRefResponse.paymentProfileId;
    this.cardData.firstName = captureByLinkRefResponse.firstName;
    this.cardData.lastName = captureByLinkRefResponse.lastName;
    this.cardData.cardLastFour = captureByLinkRefResponse.cardLastFour;
    this.cardData.transactionId = captureByLinkRefResponse.transactionId;
    this.cardData.addressLine1 = captureByLinkRefResponse.addressLine1;
    this.cardData.addressLine2 = captureByLinkRefResponse.addressLine2;
    this.cardData.city = captureByLinkRefResponse.city;
    this.cardData.state = captureByLinkRefResponse.state;
    this.cardData.zip = captureByLinkRefResponse.zip;

    this.dataSrv.cardData$.next(this.cardData);
  }

  cancelRefresh(): void {
    clearInterval(this.refreshNeeded);
    this.autoRefresh = false;
    this.processing = false;
  }
}
