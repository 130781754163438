import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
//import { PostObject } from "../post-object";
//import { error } from 'protractor';
import { ICreditCardDetails, CommonPaymentData } from '../../interfaces';
import { of } from 'rxjs';
import { CreditCardService } from 'src/app/services/creditCardService/credit-card.service';
import { faRandom, faSync, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

declare var $: any;
@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent implements OnInit {
  cardCancelled = false;
  alreadyComp: boolean = false;
  basepath: string;
  modalTitle: string = "Payment Details Status";
  estAmt: string;
  postObj: CommonPaymentData;
  pmSuccess: boolean;
  paymentAck: any = {};
  previousUrl: any;
  refPres: boolean = true;
  Close: string = "Close";
  errRes: string;
  redirUrl: any;
  postPhone: any;
  postEmailId: any;
  spoReffd: boolean = false;
  amexCard: boolean = false;
  loading: boolean = false;
  isSPM: boolean = false;
  cardNumber: string = "";
  paymentInfoType: boolean = false;
  public paymentActType: string;
  public sourcePath: string = "paymentInfo";
  public customerAcctId: number;
  public referenceNumber: any;
  public isAgentType: boolean = false;
  public isCustomerType: boolean = false;
  private errorMessage: string;
  public paymentId: number;
  errorText: string;
  UserData: ICreditCardDetails;
  paymentID: any;
  EstimateID: any;
  authText: boolean = false;
  CODType: boolean = false;
  isCardAvailable: boolean = false;
  cardMaskedNumber: string;
  today: any;
  contractInfo: any;
  customerName: string;
  showGeneralScript: boolean = false;
  showMultiItemScript: boolean = false;
  scriptRead: boolean = true;
  transferIcon = faRandom;
  refreshIcon = faSync;
  submitIcon = faArrowAltCircleRight;
  favoriteSeason: string;
  transferBackValue: string;
  seasons: string[] = ['Yes', 'No'];
  initialLoader: boolean = true;

  /* flags to show error message */
  paymentError = false;
  noData = false;
  noRefId = false;
  apiError = false;
  ivrSuccess = false;
  ivrError = false;
  refreshTrigger = false;
  refreshSuccess = false;
  noAgentId = false;
  agentId: string;
  successFullPayment = false;
  paymentFailed = false;
  paymentNoMatch = false;
  submitButtonStatus = false;
  radioDisSts = false;
  submitBtnSts = false;
  transferBasePath: string;
  enableReturnBtn: boolean =false;
  StatusIdNotSuccess: boolean=false;
  constructor(
    public httpClient: HttpClient,
    public route: ActivatedRoute,
    private his: Router,
    private creditCardService: CreditCardService
  ) {
    this.basepath = environment.commonPaymentApiBasePath;
    this.transferBasePath = environment.ivrCallTransfer;
  }

  ngOnInit() {
    this.commonPaymentCreditDetails();
  }
  
  generateYears() {
    let thisYr = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.years.push(thisYr + i);
    }
  }

  years: number[] = [];
  refrenceId: string | null;
  title = "accelpaybyphoneui";
  @ViewChild("ccimage", { read: ElementRef })
  image: ElementRef;
  @ViewChild("modShow", { read: ElementRef })
  modShow: ElementRef;

  @ViewChild("card", { read: ElementRef })
  card: ElementRef;
  @ViewChild("zip", { read: ElementRef })
  zip: ElementRef;

  @ViewChild("state", { read: ElementRef })
  state: ElementRef;
  @ViewChild("cvv", { read: ElementRef })
  cvv: ElementRef;
  @ViewChild("custname", { read: ElementRef })
  name: ElementRef;
  @ViewChild("city", { read: ElementRef })
  city: ElementRef;
  @ViewChild("addLine1", { read: ElementRef })
  addLine1: ElementRef;
  @ViewChild("addLine2", { read: ElementRef })
  addLine2: ElementRef;
  @ViewChild("month", { read: ElementRef })
  month: ElementRef;
  @ViewChild("year", { read: ElementRef })
  year: ElementRef;

  @ViewChild("email", { read: ElementRef })
  email: ElementRef;
  @ViewChild("closeloader", { read: ElementRef })
  closeloader: ElementRef;

  @ViewChild("phone", { read: ElementRef })
  phone: ElementRef;

  @ViewChild("conf", { read: ElementRef })
  Confmodal: ElementRef;

  @ViewChild("loadshow", { read: ElementRef })
  loadshow: ElementRef;

  @ViewChild("generalScriptShow", { read: ElementRef })
  generalScriptShow: ElementRef;
  @ViewChild("generalScriptClose", { read: ElementRef })
  generalScriptClose: ElementRef;
  @ViewChild("multiItemScriptShow", { read: ElementRef })
  multiItemScriptShow: ElementRef;
  @ViewChild("multiItemScriptClose", { read: ElementRef })
  multiItemScriptClose: ElementRef;

  postName: string;
  postCardNo: number | undefined;
  postCvv: number;
  postExpDt: number;
  postExpYr: number;
  postAdd1: string;
  postAdd2: string;
  postCity: string;
  postState: string;
  postZip: string;

  cardset: boolean = false;
  cardType: string = "";
  cardRegex = /^\d{16}$/;
  amexCardRegex = /^\d{15}$/;
  zipRegex = /^\d{5}$/;
  cvvRegex = /^\d{3}$/;
  amexCvvRegex = /^\d{4}$/;
  emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  phoneRegex = /\d{10}|(?:\d{3}-){2}\d{4}|\(\d{3}\)\d{3}-?\d{4}/;
  updateImage(value: number) {
    if (value == 0 || value == undefined) {
      this.image.nativeElement.src = "../assets/images/ccblank.png";
      this.amexCard = false;
      this.cardset = false;
      this.cardType = "";
    } else {
      this.cardType = value.toString();
    }
    if (this.cardset) return;

    if (value == 4) {
      this.image.nativeElement.src = "../assets/images/ccvisa.jpg";
      this.cardset = true;
      this.amexCard = false;
    }
    if (value == 5) {
      this.image.nativeElement.src = "../assets/images/ccmastercard.jpg";
      this.cardset = true;
      this.amexCard = false;
    }
    if (value == 6) {
      this.image.nativeElement.src = "../assets/images/ccdiscover.jpg";
      this.cardset = true;
      this.amexCard = false;
    }
    if (value == 3) {
      this.image.nativeElement.src = "../assets/images/ccamex.jpg";
      this.amexCard = true;
      this.cardset = true;
    }
    if (!this.cardset) {
      this.image.nativeElement.src = "../assets/images/ccblank.png";
    }
  }

  isPhonePresent() {
    return this.postPhone != undefined || this.postPhone != "";
  }

  isEmailPresent() {
    return this.postEmailId != undefined || this.postEmailId != "";
  }
  ////////////////////////////////////VALIDATION START///////////////////////////////////
  isCardValid(number: string) {
    this.cardset = false;
    this.amexCard = false;
    let num: number = this.getFirstDigit(number);
    this.updateImage(num);

    let check: boolean = false;
    if (num > 0 && this.cardset) {
      if (this.amexCard) {
        check = this.amexCardRegex.test(number);
      } else {
        check = this.cardRegex.test(number);
      }
    }
    if (!this.amexCard && number[0] === '0') {
      check = false;
    }
    this.changeBorCol(check, this.card);
    if (check) this.postCardNo = +number
    else {
      this.postCardNo = undefined;
      this.cardMaskedNumber = '';
    }
    return check;
  }
  getFirstDigit(number: string): number {
    let cardnum = +number;
    while (cardnum > 10) {
      cardnum = cardnum / 10;
    }
    return ~~cardnum;
  }
  isZipValid(zip: string) {
    let check = this.zipRegex.test(zip);
    this.changeBorCol(check, this.zip);
    if (check) this.postZip = zip;
    return check;
  }
  hideCardValue(): void {
    if (this.postCardNo && this.card.nativeElement.value == this.postCardNo) {
      let cardLength = this.postCardNo.toString().length;
      this.cardMaskedNumber = this.card.nativeElement.value;
      this.card.nativeElement.value = `${cardLength > 15 ? '*' : ''}***********${this.postCardNo.toString().slice(cardLength - 4, cardLength)}`
    } else if (!this.card.nativeElement.value) {
      this.cardMaskedNumber = '';
      this.postCardNo = undefined;
    }
  }

  isCvvValid(cvv: string) {
    let check: boolean = false;
    if (this.amexCard) {
      check = this.amexCvvRegex.test(cvv);
    } else {
      check = this.cvvRegex.test(cvv);
    }
    // this.changeBorCol(check);
    if (check) this.postCvv = +cvv;
    return check;
  }

  isCityValid(text: string) {
    let check = !(text == "" || text == undefined);
    this.changeBorCol(check, this.city);
    if (check) this.postCity = text;
    return check;
  }

  isStrAddValid(text: string) {
    let check = !(text == "" || text == undefined);
    this.changeBorCol(check, this.addLine1);
    if (check) this.postAdd1 = text;
    return check;
  }

  isNameValid(text: string) {
    let check = !(text == "" || text == undefined);
    this.changeBorCol(check, this.name);
    if (check) this.postName = text;
    return check;
  }

  isStateValid() {
    let check = this.state.nativeElement.value != "ST";

    this.changeBorCol(check, this.state);
    return check;
  }
  isYearVal() {
    let check =
      this.state.nativeElement.value != null &&
      this.state.nativeElement.value != undefined &&
      this.year.nativeElement.value != "YYYY";
    this.changeBorCol(check, this.year);
    return check;
  }

  isMonthVal() {
    let check =
      this.state.nativeElement.value != null &&
      this.state.nativeElement.value != undefined &&
      this.month.nativeElement.value != "MM";
    this.changeBorCol(check, this.month);
    return check;
  }

  isEmailValid() {
    let check = true;
    let email = this.email.nativeElement.value;
    if (!this.isSBH()) {
      check = this.emailRegex.test(email);
    } else {
      if (email != null && email != undefined && email != "")
        check = this.emailRegex.test(email);
    }
    this.changeBorCol(check, this.email);
    return check;
  }

  isPhoneValid() {
    let phone = this.phone.nativeElement.value;
    let check = true;
    if (!this.isSBH()) {
      check = this.phoneRegex.test(phone);
    } else {
      if (phone != null && phone != undefined && phone != "")
        check = this.phoneRegex.test(phone);
    }

    this.changeBorCol(check, this.phone);
    return check;
  }

  changeBorCol(status: boolean, member: ElementRef) {
    if (!status) {
      member.nativeElement.style =
        "border-color : #ff9494; box-shadow : #ff9494;";
    } else {
      member.nativeElement.style = "border-color : #28CD50;";
    }
  }

  isSBH() {
    return this.referenceNumber.slice(0, 3) == "SBH";
  }
  //////////////////////VALIDATIONS OVER/////////////////////////////

  validateAndPost() {
    if (!this.scriptRead || !this.validateAll()) { return; }
    
    this.loadshow.nativeElement.click();
    this.submitPost();
  }

  validateAll(): boolean {
    let nameVal = this.isNameValid(this.name.nativeElement.value);
    let namestrAddVal = this.isStrAddValid(this.addLine1.nativeElement.value);
    let cityVal = this.isCityValid(this.city.nativeElement.value);
    let zipVal = this.isZipValid(this.zip.nativeElement.value);
    let cvvVal = this.isCardAvailable ? this.isCvvValid(this.cvv?.nativeElement.value) : true;
    let cardVal = this.isCardValid(this.cardMaskedNumber);
    let stateVal = this.isStateValid();
    let yearVal = this.isYearVal();
    let monthVal = this.isMonthVal();
    let phoneVal = this.isPhoneValid();
    let emailVal = this.isEmailValid();
    return (
      nameVal &&
      namestrAddVal &&
      cityVal &&
      zipVal &&
      cvvVal &&
      cardVal &&
      stateVal &&
      yearVal &&
      monthVal &&
      phoneVal &&
      emailVal
    );
  }

  /* Todo As per to Luis we are disabling it */
  submitPost(): void {
    if (this.paymentActType == "CreateProfile") {
      const dataResp = this.UserData.commonPaymentData;
      const res = this.UserData;
      this.redirUrl = dataResp["redirectURL1"];
      this.paymentID = dataResp["paymentId"];
      this.EstimateID = '';

      if (dataResp.statusId == 'success' || this.paymentInfoType) {
        this.pmSuccess = true;
        this.paymentId = dataResp["paymentId"];
        this.modalTitle = "Payment Details Status";
        if (this.isAgentType || this.isCustomerType) {
          if (this.paymentActType == "CreateProfile") {
            this.modalTitle = "Card Authorization Status";
            this.authText = true;
          }
        }
        
        this.paymentAck.created_date_time = dataResp["updatedDate"];
        this.paymentAck.created_by = dataResp["updatedBy"];
        this.paymentAck.transactionId = dataResp["transactionId"];
        this.paymentAck.status = dataResp["statusId"];
        if (this.paymentAck.status != "success") {
          // incase of invalid CC details
          this.paymentAck.amount = dataResp.paymentAmount;
          this.paymentAck.redirectUrl = dataResp["redirectURL1"];
          this.redirUrl = dataResp["redirectURL1"];
          // uncommented the below
          this.card.nativeElement.value = "";
          this.year.nativeElement.value = "YYYY";
          this.month.nativeElement.value = "MM";
          this.cvv.nativeElement.value = '';
          // end of uncommented

          // added AuthAndPay condition
          if (this.isAgentType || this.isCustomerType || this.paymentActType == "CreateProfile" || this.paymentActType == "AuthAndPay") {
            this.pmSuccess = false;
            this.paymentAck.redirectUrl = dataResp["redirectURL2"];
            this.redirUrl = dataResp["redirectURL2"];
            this.modalTitle = "Error";
            this.errorText = "Invalid";
            this.errRes = "Sorry, " + this.paymentAck.status + ". Please try again with a different card.";
            if (this.isCustomerType) {
              this.errRes = "Sorry, " + this.paymentAck.status + ". Please try again with a different card. If the issue persists, you can give us a call at (855) 612-2521.";
            }
          }

        }
        this.loading = false;
        this.closeloader.nativeElement.click();
        this.modShow.nativeElement.click();
      } else {
        this.pmSuccess = false;
        this.modalTitle = "Payment Details Status";
        if (this.spoReffd)
          this.redirUrl = dataResp["redirectURL2"];
        else this.redirUrl = dataResp["redirectURL1"];
        if (this.paymentInfoType)
          this.errRes = this.UserData.errorMessage;
        else this.errRes = this.UserData.errorMessage;
        if (this.errRes == undefined) {
          this.errRes = "Unknown problem with payment processing";
        }
        this.loading = false;
        this.year.nativeElement.value = "YYYY";
        this.month.nativeElement.value = "MM";
        this.cvv.nativeElement.value = "";
        this.closeloader.nativeElement.click();
        this.modShow.nativeElement.click();
      }
    } else if (this.paymentActType === 'AuthCard') {
      // adding this condition to handle submit on paymentActType as AuthCard
      this.pmSuccess = true;
      const dataResp = this.UserData.commonPaymentData;
      if (dataResp.statusId == 'success' || this.paymentInfoType) {
        this.pmSuccess = true;
        this.paymentId = dataResp["paymentId"];
        this.modalTitle = "Card Authorization Status";
        this.authText = true;
        this.paymentAck.created_date_time = dataResp["updatedDate"];
        this.paymentAck.created_by = dataResp["updatedBy"];
        this.paymentAck.transactionId = dataResp["transactionId"];
        this.paymentAck.status = dataResp["statusId"];
        this.paymentAck.amount = dataResp.paymentAmount;
        this.paymentAck.redirectUrl = dataResp["redirectURL1"];
        this.redirUrl = dataResp["redirectURL1"];
        // uncommented the below
        this.card.nativeElement.value = "";
        this.year.nativeElement.value = "YYYY";
        this.month.nativeElement.value = "MM";
        this.cvv.nativeElement.value = '';
      } else {
        this.pmSuccess = false;
        this.paymentAck.redirectUrl = dataResp["redirectURL2"];
        this.redirUrl = dataResp["redirectURL2"];
        this.modalTitle = "Error";
        this.errorText = "Invalid";
        this.errRes = "Sorry, " + this.paymentAck.status + ". Please try again with a different card.";
        if (this.isCustomerType) {
          this.errRes = "Sorry, " + this.paymentAck.status + ". Please try again with a different card. If the issue persists, you can give us a call at (855) 612-2521.";
        }
      }
      this.loading = false;
    } else {
      this.pmSuccess = false;
      if (this.isCustomerType || this.isAgentType) {
        this.modalTitle = "Payment Details Status";
        this.errorText = "Request Error";
        //  this.errorMessage = this.paymentInfoType ? this.UserData.errorMessage ? err.error.errors[0] : err.error.errorDetails ? err.error.errorDetails[0] : err.error.error : err.error["errorMessage"];
        this.errRes = this.errorText + " : " + this.errorMessage;
      } else {
        this.modalTitle = "Payment Details Status";
        // this.errRes = this.paymentInfoType ? this.UserData.errorMessage ? err.error.errors[0] : err.error.error : err.error["errorMessage"];
        this.loading = false;
        this.card.nativeElement.value = "";
        this.year.nativeElement.value = "YYYY";
        this.month.nativeElement.value = "MM";
        this.cvv.nativeElement.value = "";
      }
      this.closeloader.nativeElement.click();
      this.modShow.nativeElement.click();
    }
  }


  makeCancelCall() {
    this.cardCancelled = true;
    this.loadshow.nativeElement.click();
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", "Access-Control-Allow-Origin": "localhost:5300" }),
    };

    /* const headers = new Headers();
    headers.append("Content-Type", "application/json"); */
    const userData = this.UserData.commonPaymentData;
    const postObj = {
      'paymentId': userData.paymentId,
      'clientId': userData.clientId,
      'referenceId': userData.referenceId,
      'requestId': userData.requestId,
    }
    this.httpClient
      .put(
        `${this.basepath}/cancelPayment`,
        postObj,
        httpOptions
      )
      .subscribe(
        (res: any) => {
          const dataResp = res["commonPaymentData"];
          this.redirUrl = dataResp["redirectURL1"];
          this.paymentID = dataResp[""];
          this.EstimateID = dataResp[""];
          if (res["successStatus"] == true || this.paymentInfoType) {
            this.pmSuccess = true;
            this.paymentAck.created_date_time =
              dataResp["createdDate"];
            this.paymentAck.created_by = dataResp["createdBy"];
            this.paymentAck.transactionId =
              dataResp["transactionId"];
            this.paymentAck.status = dataResp["statusId"];
            this.paymentAck.amount = dataResp["paymentAmount"];
            this.paymentAck.redirectUrl =
              dataResp["redirectURL1"];
            this.modalTitle = "Payment Details canceled";
            /* console.log(this.paymentAck); */
            this.loading = false;

            /*  this.card.nativeElement.value = "";
             this.year.nativeElement.value = "YYYY";
             this.month.nativeElement.value = "MM"; */
            // this.cvv.nativeElement.value = "";
            this.closeloader.nativeElement.click();
            this.modShow.nativeElement.click();
          } else {
            this.pmSuccess = false;
            if (this.paymentInfoType)
              this.errRes = res["errorMessage"];
            else this.errRes = res["errorMessage"];
            if (this.errRes == undefined) {
              this.errRes = "Unknown problem with payment processing";
            }
            this.modalTitle = "Payment Details canceled";
            this.loading = false;

            /*  this.card.nativeElement.value = "";
             this.year.nativeElement.value = "YYYY";
             this.month.nativeElement.value = "MM"; */
            //this.cvv.nativeElement.value = "";
            this.closeloader.nativeElement.click();
            this.modShow.nativeElement.click();
          }
        },

        (err) => {
          this.loading = false;
          console.log(err);
          this.pmSuccess = false;
          this.modalTitle = "Payment cancelled";
          this.redirUrl = "nonUrlSituation";
          /*  this.card.nativeElement.value = "";
           this.year.nativeElement.value = "YYYY";
           this.month.nativeElement.value = "MM"; */
          //this.cvv.nativeElement.value = "";
          this.closeloader.nativeElement.click();
          this.modShow.nativeElement.click();
        }
      );
  }

  redirectToCaller() {
    this.loading = false;
    if (this.referenceNumber.slice(0, 3) == "CPT") {
      window.parent.postMessage(
        `CustomerName=${this.name.nativeElement.value}&status=${this.modalTitle.toLowerCase().includes("cancelled") ? 'CANCELLED' : this.pmSuccess ? 'SUCCESS' : 'FAILED'}&referenceId=${this.UserData.commonPaymentData.referenceId}&card=${this.cardNumber}&cType=${this.cardType}&message=${this.modalTitle}&paymentId=${this.paymentId}`,
        "*"
      );
    } else {
      if (
        this.redirUrl != undefined &&
        this.redirUrl != null &&
        this.redirUrl != "nonUrlSituation"
      ) {
        this.paymentID = !this.paymentID ? "" : this.paymentID;
        this.EstimateID = !this.EstimateID ? undefined : this.EstimateID;
        if (!this.redirUrl.includes("referenceId")) {
          this.redirUrl += `&status=${this.modalTitle.toLowerCase().includes("cancelled") ? 'CANCELLED' : this.pmSuccess ? 'SUCCESS' : 'FAILED'}&referenceId=${this.UserData.commonPaymentData.referenceId}&paymentID=${this.paymentID}&EstimateID=${this.EstimateID}`;
        }
        if (this.isAgentType) {
          this.redirUrl =
            this.redirUrl + `&card=${this.cardNumber ? this.cardNumber : null}&cType=${this.cardType}`;
        }
        /* console.log(this.redirUrl); */
        this.loadshow.nativeElement.click();
        window.location.href = this.redirUrl;
      } else if (this.UserData.commonPaymentData["redirectURL1"]) {
        this.redirUrl = this.modalTitle.toLowerCase().includes("cancelled") ? this.UserData.commonPaymentData["redirectURL2"] : this.pmSuccess ? this.UserData.commonPaymentData["redirectURL1"] : this.UserData.commonPaymentData["redirectURL2"];
        this.paymentID = !this.paymentID ? "" : this.paymentID;
        this.EstimateID = !this.EstimateID ? undefined : this.EstimateID;
        if (!this.redirUrl.includes("referenceId")) {
          this.redirUrl += `&status=${this.modalTitle.toLowerCase().includes("cancelled") ? 'CANCELLED' : this.pmSuccess ? 'SUCCESS' : 'FAILED'}&referenceId=${this.UserData.commonPaymentData.referenceId}&paymentID=${this.paymentID}&EstimateID=${this.EstimateID}`;
        }
        if (this.isAgentType) {
          this.redirUrl =
            this.redirUrl + `&card=${this.cardNumber}&cType=${this.cardType}`;
        }
        this.loadshow.nativeElement.click();
        window.location.href = decodeURIComponent(this.redirUrl);
      } else return;
    }
  }

  openGeneralScript() {
    this.today = new Date();
    this.generalScriptShow.nativeElement.click();
  }

  closeGeneralScript() {
    this.scriptRead = true;
    this.generalScriptShow.nativeElement.style = "display: none;";
  }

  openMultiItemScript() {
    this.today = new Date();
    this.multiItemScriptShow.nativeElement.click();
  }

  closeMultiItemScript() {
    this.scriptRead = true;
    this.multiItemScriptShow.nativeElement.style = "display: none;";
  }

  radioCall = () => {
    if (this.favoriteSeason === 'Yes') {
      this.transferBackValue = 'Y'
    } else {
      this.transferBackValue = 'N'
    }

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", "Access-Control-Allow-Origin": "localhost:5300" }),
    };
    this.httpClient
      .post(`${this.basepath}callstatus/${this.refrenceId}/${this.transferBackValue}`, httpOptions)
      .subscribe({/* }); */
        /* this.creditCardService.successfulTransferBack(this.transferBackValue , this.referenceNumber).subscribe({ */
        next: (dts) => {
          //  this.submitBtnSts = false;
          console.log(dts, 'succ');
        }, error: (err) => {
          console.log(err, 'err');
        }
      })
  }

  refreshDetails = () => {
    this.initialLoader = true;
    this.refreshTrigger = true;
    this.refreshSuccess = false;
    this.noAgentId = false;
    this.noRefId = false
    this.apiError = false;
    this.ivrError = false;
    this.ivrSuccess = false;
    this.successFullPayment = false;
    this.paymentFailed = false;
    this.paymentNoMatch = false;
    this.submitButtonStatus = false;
    if (this.referenceNumber) {
      this.commonPaymentCreditDetails();
      this.radioDisSts = false;
    }
    else {
      this.initialLoader = false;
      this.noRefId = true;
    }
  }

  ivrTransfer = () => {
    this.agentId = (<HTMLInputElement>document.getElementById("agentId")).value;
    this.initialLoader = true;
    this.refreshSuccess = false;
    this.refreshTrigger = false;
    this.ivrSuccess = false;
    this.ivrError = false;
    this.noRefId = false;
    this.noAgentId = false;
    this.successFullPayment = false;
    this.paymentFailed = false;
    this.paymentNoMatch = false;
    this.submitButtonStatus = false;
    this.StatusIdNotSuccess = false;
    if (!this.agentId || !this.referenceNumber) {
       this.initialLoader = false;
       this.noAgentId = !this.agentId;
       this.noRefId = !this.referenceNumber;
       return;
    }

    // commented unused code
    // const httpOptions = {
    //   headers: new HttpHeaders({ "Content-Type": "application/json", "Access-Control-Allow-Origin": "localhost:5300" }),
    // };

    this.creditCardService.transferCall(this.agentId, this.refrenceId || this.referenceNumber).subscribe({
      next: (res: any) => {
        if (res.Status === 'OK') {
          this.ivrSuccess = true;
          this.radioDisSts = true;
          this.submitBtnSts = true;
        }
      },
      error: () => {
        this.initialLoader = false;
        this.ivrError = true;
      },
      complete: () => {
        this.initialLoader = false;
      }
    });
  }

  commonPaymentCreditDetails = () => {
    // removed from try catch and commenting for now
    // try {
    // } catch (err) {
    //   console.log(err);
    //   this.refPres = false;
    //   // this.errRes = this.paymentInfoType ? err ? err.error.errors[0] : err.error.error : err["errorMessage"];
    //   return;
    // }

    this.generateYears();
    this.StatusIdNotSuccess = false;

    this.route.queryParamMap.subscribe((param) => {
      if (!param || !param.get('refId')) { this.refPres = false; this.errRes = "Invalid details received"; }
      
      this.refrenceId = param.get('refId');
      this.referenceNumber = this.refrenceId;

      if (this.route.snapshot.params["sourcePath"] === this.sourcePath) {
        this.paymentInfoType = true;
        const reference = this.referenceNumber.slice(0, 3);
        switch(reference) {
          case 'AGT':
            this.isAgentType = true;
            break;
          case 'CPT':
            this.isCustomerType = true;
            break;
          case 'SPO':
            case 'CPT':
              this.spoReffd = true;
              break;
          case 'SPM':
            this.isSPM = true;
            break;
        }
      } else {
        this.paymentInfoType = false;
      }
    });

    if (this.refPres && !['SPO', 'SPM'].includes(this.referenceNumber.slice(0, 3))) { this.isCardAvailable = true; }

    this.loading = true;
    this.refrenceId && this.creditCardService.getCreditCardDetails(this.refrenceId, this.paymentInfoType).subscribe({
      next: (resp) => {
        if (this.refreshTrigger) { this.refreshSuccess = true; }
        const dataResp: any = this.paymentInfoType ? resp : resp.commonPaymentData;
        this.UserData = resp;

        if (resp["errorMessage"] == "Data Not Found") {
          this.refPres = false;
          this.errRes = "No data found";
          return;
        } else {
          if (dataResp?.["statusId"] == "success") {
            // uncommented the below code
            this.alreadyComp = true;
            this.pmSuccess = true;
            this.modShow.nativeElement.click();
            this.Close = "Back to Application";
            // end of uncommented

            this.paymentAck.redirectUrl =
              dataResp["redirectURL1"];
            this.redirUrl = dataResp["redirectURL1"];
            this.cardNumber = `XXXX-XXXX-XXXX-${dataResp["cardLastFour"]}`;
            this.paymentID = dataResp.paymentId;
            this.cardType = "-";
            this.modalTitle = "Payment Details Status";
          } else {
            if (dataResp?.["statusId"] == "Profile Not Created") {
              this.StatusIdNotSuccess = true;
            }
          }
          this.postPhone = dataResp?.["phone"];

          this.postEmailId = dataResp?.["emailId"];
          this.postAdd1 = dataResp?.["addressLine1"];
          this.postAdd2 = dataResp?.["addressLine2"];
          this.postCity = dataResp?.["city"];
          this.postState = dataResp?.["state"];
          this.postZip = dataResp?.["zip"];
          this.estAmt = dataResp?.["paymentAmount"];
          this.customerAcctId = dataResp?.["customerAccountId"];
          this.contractInfo = resp["contractInfo"];
          if (this.contractInfo) {
            this.customerName = (this.contractInfo["personLastName"] != null
              ? this.contractInfo["personFirstName"] + " " + this.contractInfo["personLastName"]
              : this.contractInfo["personFirstName"]).toLowerCase();
          }
          if (dataResp?.["clientId"] === "WAR" || dataResp?.["clientId"] === "SBH") {
            this.showGeneralScript = true;
            this.scriptRead = false;
          }
          if (dataResp?.["clientId"] === "WAR") {
            this.showMultiItemScript = true;
            this.scriptRead = false;
          }
          this.postName =
            dataResp?.["lastName"] != null
              ? dataResp?.["firstName"] +
              " " +
              dataResp?.["lastName"]
              : dataResp?.["firstName"];
          this.referenceNumber = dataResp?.["referenceId"];
          if (dataResp?.["paymentActionType"] == "CreateProfile") {
            this.paymentActType = "CreateProfile";
            this.radioDisSts = false;
            // below conditions are uncommnted
          } else if (dataResp?.["paymentActionType"] == "Hold") {
            this.paymentActType = "Hold";
          } else if (dataResp?.["paymentActionType"] == "Customer Initiated") {
            this.paymentActType = dataResp?.["paymentActionType"];
          } else if(dataResp?.["paymentActionType"] === "AuthCard") {
            this.paymentActType = "AuthCard";
          } else if (dataResp?.["paymentActionType"] == 'ReleaseAndPay') {
            // added AuthAndPay condition along with ReleaseAndPay
            this.paymentActType = "AuthAndPay";
            // this.postObj.createdBy = dataResp?.["createdBy"];
            // end of uncommented conditions
          } else {
            this.paymentActType = "AuthAndPay";
            // this.paymentActType = "ProcessByPaymentProfile";
            // this.enableReturnBtn = true;
            // this.refreshSuccess =false;
          }

          if (!this.paymentInfoType && (JSON.parse(this.estAmt) == 0 || this.estAmt == undefined)) {
            this.refPres = false;
            this.errRes = "No data found";
            return;
          }
        }
      },
      error: (err) => {
        console.log(err);
        this.initialLoader = false
        this.refPres = false;
        // uncommenting the below line as per old code
        this.errRes = this.paymentInfoType ? (err.error.errors ? err.error.errors[0] : err.error.error) : err.error["errorMessage"];
        return;
      },
      complete: () => { console.info('complete'); this.initialLoader = false; }
    })
  }
}
