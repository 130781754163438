<div class="confirm-modal">
    <h4 mat-dialog-title><strong>Are you sure?</strong></h4>
    <mat-dialog-content>
       <span>You are trying to cancel the payment. You want to proceed?</span>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
            mat-flat-button
            color="basic"
            [mat-dialog-close]="true"
        >
            Cancel
        </button>
        <button
            mat-flat-button
            color="primary"
            [mat-dialog-close]="false"
        >
            Proceed
        </button>
    </mat-dialog-actions>
</div>
