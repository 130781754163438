<div class="link-section">
    <div class="mode">
        <label>Send Link Mode:</label>
        <mat-radio-group aria-label="Send Link mode" [(ngModel)]="sendLinkMode">
            <mat-radio-button [value]="'mobile'" >Mobile</mat-radio-button>
            <mat-radio-button [value]="'email'" >Email</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="transfer">
        <div class="transfer-fields">
            <mat-form-field>
                <mat-label>Mobile Number:</mat-label>
                <input
                    matInput
                    placeholder="Enter Mobile Number"
                    id="customer-mobile-number"
                    type="text"
                    data-auto-id="customer-mobile-number"
                    [(ngModel)]="customerMobileNumber"
                    mask='(000) 000-0000'
                    minlength="10"
                    autofocus
                >
            </mat-form-field>
            <small *ngIf="sendLinkMode === 'mobile' && !checkFieldValidation()">Phone number is invalid.</small>
        
            <mat-form-field>
                <mat-label>Email Id:</mat-label>
                <input
                    matInput
                    placeholder="Enter Email Id"
                    id="customer-email-id"
                    type="email"
                    data-auto-id="customer-email-id"
                    [(ngModel)]="customerEmailId"
                >
            </mat-form-field>
            <small *ngIf="sendLinkMode === 'email' && !checkFieldValidation()">Email is invalid.</small>
        </div>

        <button mat-flat-button
            color="primary"
            [disabled]="!enableSendLink()"
            (click)="sendLink()"
            data-auto-id="send-link"
        >
            Send Link
        </button>
    </div>
</div>
