import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService { 
  uniqBy(arrayToBeUniqued: any[], iteratee: string): any[] {
    if (!iteratee.length) { return []; }
    const checkObject = (item: any) => item[iteratee] || null;

    return arrayToBeUniqued.filter(
      (iteratingObject: any, index: any, self: any) => 
        index === self.findIndex((object: any) =>
         (checkObject(iteratingObject) && checkObject(object)) ? checkObject(iteratingObject) === checkObject(object) : false)
    );
  }
}
