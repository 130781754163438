import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from "./app.component";
import { PaymentsComponent } from './components/payments/payments.component';

const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'payment', component: PaymentsComponent },
  { path: 'commonPayment/:sourcePath', component: PaymentsComponent },
  {
    path: 'timx-payment/:sourcePath',
    loadChildren: () =>
      import('./components/timx-payment/timx-payment.module').then(
        (m) => m.TimxPaymentModule
      ),
  },
  {
    path: 'common-payment/:sourcePath',
    loadChildren: () =>
      import('./components/common-payment/common-payment.module').then(
        (m) => m.CommonPaymentModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
