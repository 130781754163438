<div class="details" *ngIf="cardData">
    <div class="head-info-section">
        <div class="amount">
            <strong><label>Amount:</label>{{cardData?.paymentAmount | currency}}</strong>
        </div>

        <div class="navigation">
            <a (click)="redirectToOriginalPayment()">
                <strong><mat-icon>chevron_left</mat-icon> Original Payment Screen</strong>
            </a>
        </div>
    </div>

    <div class="form-fields">
        <mat-form-field>
            <mat-label>Card holder Name</mat-label>
            <input
                matInput
                id="card-holder-name"
                [(ngModel)]="cardData.fullName"
                type="text"
                data-auto-id="card-holder-name"
                readonly
            >
        </mat-form-field>

        <mat-form-field>
            <mat-label>Address</mat-label>
            <input
                matInput
                id="card-address"
                [(ngModel)]="cardData.addressLine1"
                type="text"
                data-auto-id="card-address"
                readonly
            >
        </mat-form-field>

        <mat-form-field>
            <mat-label>City</mat-label>
            <input
                matInput
                id="card-city"
                [(ngModel)]="cardData.city"
                type="text"
                data-auto-id="card-city"
                readonly
            >
        </mat-form-field>

        <mat-form-field>
            <mat-label>State</mat-label>
            <input
                matInput
                id="card-state"
                [(ngModel)]="cardData.state"
                type="text"
                data-auto-id="card-state"
                readonly
            >
        </mat-form-field>

        <mat-form-field>
            <mat-label>Zip</mat-label>
            <input
                matInput
                id="card-zip"
                [(ngModel)]="cardData.zip"
                type="text"
                data-auto-id="card-zip"
                readonly
            >
        </mat-form-field>

        <mat-form-field>
            <mat-label>Card Last Four</mat-label>
            <input
                matInput
                id="card-last-four"
                type="text"
                data-auto-id="card-last-four"
                [(ngModel)]="cardData.cardLastFour"
                readonly
            >
        </mat-form-field>
    </div>

    <div class="button-group">
        <button
            mat-flat-button
            color="primary"
            *ngIf="cardData.paymentCategory !== 'TIMX'"
            (click)="submitCard()"
            [disabled]="!cardData.paymentProfileId || !termsAndConditionsRead"
            data-auto-id="charge-card"
            [matTooltip]="cardData.cardLastFour ? 'Please read Payment Collection conditions to enable Submit' : 'Not enough details available to Submit'"
            [matTooltipPosition]="'above'"
            [matTooltipDisabled]="termsAndConditionsRead"
        >
            Submit
        </button>

        <button
            mat-flat-button
            color="warn"
            *ngIf="cardData.paymentCategory !== 'TIMX'"
            (click)="confirmCancel()"
            data-auto-id="cancel"
        >
            Cancel
        </button>
    </div>

    <br>

    <div class="terms-and-conditions" *ngIf="cardData.paymentCategory !== 'TIMX'">
        <a (click)="openTermsAndConditions(false)"><strong>General Payment Collection</strong></a>

        <a (click)="openTermsAndConditions(true)"><strong>P3 Appliance Multi-item</strong></a>
    </div>
</div>

<div *ngIf="!cardData">
    <span data-auto-id="no-data-message">No Card details available on file.</span>
</div>
