import { Component, OnInit, OnDestroy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';

import { CommonPaymentData } from 'src/app/interfaces';
import { ICancelPayload, IUpdateCardPayload, ICommonPaymentData } from './card-details.interface';

import { DataService } from 'src/app/services/data/data.service';
import { CreditCardService } from 'src/app/services/creditCardService/credit-card.service';
import { MessageService } from 'src/app/services/message/message.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

import { TermsModalComponent } from '../modals/terms-modal/terms-modal.component';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { environment } from '@environment';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss']
})
export class CardDetailsComponent implements OnInit, OnDestroy {
  cleaner$ = new Subject<void>();
  cardData: ICommonPaymentData | null;
  cardOnFileSelected: boolean;
  termsAndConditionsRead: boolean = false;

  constructor(
    private dataSrv: DataService,
    public dialog: MatDialog,
    private creditCardSrv: CreditCardService,
    private messageSrv: MessageService,
    private loggerSrv: LoggerService,
  ) { }

  ngOnDestroy(): void {
    this.cleaner$.next();
    this.cleaner$.complete();
  }

  ngOnInit(): void {
    this.dataSrv.cardData$.pipe(takeUntil(this.cleaner$)).subscribe({
      next: (data) => {
        if (!data) { this.cardData = null; return; }
        if (data.paymentProfileId) { this.termsAndConditionsRead = false; }
        this.buildCardData(data);
      }
    });
  }

  buildCardData(data: CommonPaymentData): void {
    this.cardData = { 
      ...data,
      fullName: `${data.firstName} ${data.lastName}`,
      cardLastFour: data.cardLastFour || '',
    };
  }

  openTermsAndConditions(isMultiItem: boolean): void {
    if (!this.cardData) { this.loggerSrv.log('No card details available.'); return; }

    const dialogRef = this.dialog.open(TermsModalComponent, {
      data: {
        customerName: this.cardData.fullName,
        contractInfo: null,
        today: new Date().toDateString(),
        estAmt: this.cardData.paymentAmount,
        productName: this.cardData.revenueType,
        multiItem: isMultiItem,
      },
    });

    dialogRef.afterClosed().pipe(takeUntil(this.cleaner$)).subscribe({
      next: () => {
        this.termsAndConditionsRead = true;
      }
    });
  }

  confirmCancel(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {},
    });

    dialogRef.afterClosed().pipe(takeUntil(this.cleaner$)).subscribe(result => {
      if (!result) { this.cancelCard(); }
    });
  }

  cancelCard(): void {
    if (!this.cardData) { this.loggerSrv.log('No card details available to cancel.'); return; }

    const payload: ICancelPayload = {
      paymentId: this.cardData.paymentId,
      clientId: this.cardData.clientId,
      referenceId: this.cardData.referenceId,
      requestId: this.cardData.requestId,
    }

    this.creditCardSrv.cancelPayment(payload).pipe(takeUntil(this.cleaner$)).subscribe({
      next: (res) => {
        if (res.errorMessage === 'Payment Cancelled By User') {
          this.messageSrv.showMessage('Card details has been cancelled successfully', 'success');
          this.redirectToApplication(res?.commonPaymentData?.redirectURL1);
        }
      },
      error: (err) => {
        this.messageSrv.showMessage(err.error?.errorMessage ? err.error.errorMessage : 'Error occurred while cancelling, Please try again later.', 'error');
        this.redirectToApplication(err?.error?.commonPaymentData?.redirectURL2);
      }
    });
  }

  submitCard(): void {
    if (!this.cardData) { this.loggerSrv.log('No card details available to submit.'); return; }

    const payload: IUpdateCardPayload = {
      paymentId: this.cardData.paymentId,
      referenceId: this.cardData.referenceId,
      clientId: this.cardData.clientId,
      paymentProfileId: this.cardData.paymentProfileId,
      firstName: this.cardData.firstName,
      lastName: this.cardData.lastName,
      cardNumber: this.cardData.cardLastFour,
      transactionId: this.cardData.transactionId,
      addressLine1: this.cardData.addressLine1,
      addressLine2: this.cardData.addressLine2,
      city: this.cardData.city,
      state: this.cardData.state,
      zip: this.cardData.zip,
    };

    this.creditCardSrv.submitPayment(payload).pipe(takeUntil(this.cleaner$)).subscribe({
      next: (response) => {
        if (response.successStatus) {
          this.messageSrv.showMessage('Card Details has been submitted successfully.', 'success');
          this.dataSrv.cardData$.next(response.commonPaymentData);
          this.redirectToApplication(response?.commonPaymentData?.redirectURL1);
        } else {
          this.messageSrv.showMessage(response.errorMessage, 'error');
        }
      },
      error: (err) => {
        this.messageSrv.showMessage(err.error?.errorMessage ? err.error.errorMessage : 'Error occurred while submitting details. Please try again later.', 'error');
      }
    });
  }

  redirectToApplication(url: string | null): void {
    if (!url || url === '') { this.loggerSrv.log('No redirect url available.'); return; }

    setTimeout(() => {
      window.location.href = url;
    }, 1000);
  }

  redirectToOriginalPayment(): void {
    if (!this.dataSrv.refId$.getValue()) { this.loggerSrv.log('No reference Id available to redirect to Original Payment UI.'); return; }

    window.location.href = `${environment.originalPaymentUIDomain}commonPayment/paymentInfo?refId=${this.dataSrv.refId$.getValue()}`;
  }
}
